body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  height: 100vh;
}

.App {
  margin: 0;
  padding: 0;
  height: 100vh;
}